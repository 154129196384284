<!-- eslint-disable vue/valid-v-model -->
<template>
  <div class="box">
    <!-- <div class="loading" v-show="customer==='jinlang'">
      <div ref="loading" class="block"></div>
    </div> -->
    <!-- <progress v-show="customer==='qingtian'" ref="progressRef" value="20" max="100"></progress> -->
    <progress ref="progressRef" value="20" max="100"></progress>

    <div class="text" v-if="!isFinish">
      <p>农民勘测信息已提交</p>
      <p>正在生成图纸中</p>
      <!-- <p>当前排队人数10人</p>
      <p>预计15分钟</p> -->
    </div>
    <div class="text" v-if="isFinish">
      <p>图纸已生成</p>
    </div>

    <!-- <router-link :class="{light:isFinish}" replace to:></router-link> -->
    <div class="grey" :class="{light:isFinish}" @click="openLayoutView">
      预览排布图

    </div>
    <!-- <iframe :src="src" style="width: 100%;height: 100vh" v-if="openFil" frameborder="0"></iframe> -->

    <div class="grey" :class="{light:isFinish}" @click="openSideView">
      预览侧视图
    </div>
    <div v-if="drawingNum1" class="grey" :class="{light:isFinish}" @click="openBackView">
      预览后视图
    </div>
    <div class="grey" :class="{light:isFinish}" @click="openWiringView">
      预览接线图
    </div>
    <!-- 锦浪用 -->
    <!-- <div class="grey" :class="{light:isFinish}" @click="returnWX">
      返回晶享宝
    </div> -->
    <!-- 晴天用 -->
    <a class="grey aaa" :class="{light:isFinish}" :href="returnwx" style="color:white;">返回小程序</a>

    <van-popup
      v-model:show="show"
      closeable
      close-icon="close"
      position="bottom"
      :style="{ height: '50%' }"
    >
      <iframe width="100vw" height="100vh" :src="lookImg" frameborder="0"></iframe>
    </van-popup>
  </div>
</template>

<script>
import {
  ref,
  reactive,
  onMounted,
  nextTick,
  onBeforeUnmount,
  toRefs
} from 'vue'
import { useRouter } from 'vue-router'
import { UpdataTask, GetList, GetTaskState, ProjectReconnaissance, ProjectErrorInfo, DownloadPdfPic } from "@/api/api"
import { Dialog, Toast } from 'vant'
import wx from 'weixin-js-sdk'

export default {
  setup() {
    const router = useRouter()
    const loading = ref(null)
    const progressRef = ref(null)
    // const VanDialog = Dialog.Component;
    const urldat = JSON.parse(sessionStorage.getItem('URLquery'))
    // console.log(urldat, '88888888888888')
    const data = reactive({
      timer: null,
      timer1: null,
      customer: window.customer,
      layoutId: "",
      sideId: "",
      backId: "",
      wiringId: "",
      isFinish: false,
      second: 40,
      drawingNum: 8,
      drawingNum1: sessionStorage.getItem('roofType') !== "斜屋顶",
      // openFil: true,
      datasrc: '',
      // urlFil: 'http://112.35.62.183:9168/', // 部署锦浪
      // urlFil: 'http://192.168.1.29:9169/', // 部署晴天
      // urlFil: 'https://chuiyang.solarqt.com:9169/', // 客户预览pdf  打包118服务器时需放开
      // urlFil: 'https://34w209836a.goho.co/', // 测试47服务器时需放开
      urlFil: 'https://27e39789r0.oicp.vip/', // 花生壳映射
      show: false,
      lookImg: '',
      www: [],
      returnwx: 'https://yunapp.solarqt.com/Rent/editSurveyInfo.html?orderid=' + urldat.project_id + '&menu=现场勘察'
    })

    // router.afterEach((to, from, next) => {
    //   console.log(from.path, '99999999999')
    //   console.log(to.path, '99999999999')
    //   if (from.path === '/confirmLayout') {
    //     console.log('从哪里来到哪里去')
    //   }
    //   // 刷新页面方法
    //   // location.reload()
    //   // }
    // })

    onMounted(() => {
      if (sessionStorage.getItem('getListImg') === null) {
        data.isFinish = false
        sessionStorage.getItem('roofType') === '平屋顶' ? data.drawingNum = 8 : data.drawingNum = 6
        UpdataTask(sessionStorage.getItem('roofType') === '平屋顶' ? 2 : 5).then(() => { // 第二次更新出图任务
          data.timer = setInterval(() => {
            GetTaskState(sessionStorage.getItem('roofType') === '平屋顶' ? 2 : 5).then(ress => { // 同样是需要时间,轮询获取状态
              if (ress.data.data.jsonStatus === 0 || ress.data.data.jsonStatus === 1) {
                Toast.loading({
                  message: ress.data.data.msg,
                  forbidClick: true,
                  duration: 0
                })
              }
              if (ress.data.data.jsonStatus === 2 || ress.data.data.jsonStatus === 5) { // 状态为2或者5表示出图任务成功
                Toast.clear()
                clearInterval(data.timer)
                data.timer1 = setInterval(() => {
                  data.second--
                  if (data.second === 0) {
                    clearInterval(data.timer1)
                    ProjectErrorInfo().then(result => { // 超过设定的时间(second=40s)后台出图没有反馈,就调用异常接口,弹出异常内容
                      if (result.data.success) {
                        Dialog.alert({
                          className: "no-title",
                          message: result.data.data.error,
                          showCancelButton: true,
                          confirmButtonText: '转人工设计'
                        })
                          .then(() => {
                            ProjectReconnaissance()
                            router.push({ name: 'Home', query: JSON.parse(sessionStorage.getItem('URLquery')) })
                          })
                          .catch(() => {
                            router.push({ name: 'Home', query: JSON.parse(sessionStorage.getItem('URLquery')) })
                          });
                      }
                    })
                  }
                  GetList().then(res => { // 如果任务正常,就获取出图的文件列表
                    if (res.data.success) {
                      console.log(res, '获取图片---------------------')
                      if (res.data.data.length === data.drawingNum) { // 生成图纸每次8张,斜屋顶生成6张
                        clearInterval(data.timer1)
                        data.isFinish = true
                        ProjectReconnaissance() // 出图成功就回传一份数据给晴天
                        var qqq = JSON.stringify(res.data.data)
                        sessionStorage.setItem('getListImg', qqq)

                        res.data.data.forEach(element => {
                          // data.www.push(element)
                          if (element.fileType === 2) {
                            if (element.contentType === 1) {
                              data.layoutId = element.fileID
                            } else if (element.contentType === 2) {
                              data.sideId = element.fileID
                            } else if (element.contentType === 3) {
                              data.backId = element.fileID
                            } else {
                              data.wiringId = element.fileID
                            }
                          }
                        });
                      }
                    }
                  })
                }, 1000)
              }
              // if (ress.data.data.jsonStatus === 3) {
              //   clearInterval(data.timer1)
              //   Toast.clear()
              //   ProjectErrorInfo().then(result => { // 调用这个异常接口,在前端弹出异常的内容
              //     if (result.data.success) {
              //       if (result.data === null) {
              //         Dialog.alert({
              //           message: '出图错误'
              //         }).then(() => {
              //           router.push({ name: 'Home', query: JSON.parse(sessionStorage.getItem('URLquery')) })// 异常就返回首页
              //         })
              //       }
              //       // Dialog.alert({
              //       //   message: result.data.data.error
              //       // }).then(() => {
              //       //   router.push({ name: 'Home', query: JSON.parse(sessionStorage.getItem('URLquery')) })// 异常就返回首页
              //       // })
              //     }
              //   })
              // }
            })
          }, 3000)
        })

        nextTick(() => {
          data.timer = setInterval(() => {
            methodsMap.loadingEvent()
          }, 500)
        })
      } else if (sessionStorage.getItem('getListImg') !== null) {
        data.isFinish = true
        progressRef.value.value = 100
        var getimglist = JSON.parse(sessionStorage.getItem('getListImg'))
        getimglist.forEach(element => {
          if (element.fileType === 2) {
            if (element.contentType === 1) {
              data.layoutId = element.fileID
            } else if (element.contentType === 2) {
              data.sideId = element.fileID
            } else if (element.contentType === 3) {
              data.backId = element.fileID
            } else {
              data.wiringId = element.fileID
            }
          }
        });
      }
    })

    onBeforeUnmount(() => {
      clearInterval(data.timer)
    })
    const methodsMap = {
      loadingEvent() {
        progressRef.value.value = progressRef.value.value + 6
        if (data.isFinish) {
          progressRef.value.value = 100
        } else {
          if (progressRef.value.value === 100) {
            progressRef.value.value = 20
          }
        }
        // loading.value.style.width = 336 + 'px'
        // if (!data.isFinish) {
        //   progressRef.value.value = progressRef.value.value + 6
        //   loading.value.style.width = (Number(loading.value.style.width.replace('px', '')) - 28) + 'px'
        // } else {
        //   loading.value.style.width = 0
        //   clearInterval(data.timer)
        //   data.isFinish = true
        // }
      },

      openLayoutView() {
        data.datasrc = ""
        const dataOne = data.layoutId
        console.log(dataOne, 'ididididi')
        DownloadPdfPic(dataOne).then(res => {
          console.log(res.data.data, '8888')
          data.datasrc = res.data.data
        })
        setTimeout(() => {
          router.push({
            name: 'PreviewPdf',
            params: data.urlFil + data.datasrc
          })
        }, 1000)

        // data.show = true
        // data.lookImg = data.urlFil + data.datasrc
        // console.log(data.lookImg, '888lookimg')

        // window.location.href = window.api.apiURL + "File/Download?fileId=" + data.layoutId;
        // window.open(window.api.apiURL + "File/DownloadPdfPic?fileId=" + data.layoutId)
      },

      openSideView() {
        const dataOne = data.sideId
        DownloadPdfPic(dataOne).then(res => {
          data.sideId = res.data.data
        })
        setTimeout(() => {
          router.push({
            name: 'PreviewPdf',
            params: data.urlFil + data.sideId
          })
          // clearInterval(data.timer)
        }, 1000)
        // window.open(window.api.apiURL + "File/Download?fileId=" + data.sideId)
      },
      openBackView() {
        const dataOne = data.backId
        DownloadPdfPic(dataOne).then(res => {
          data.backId = res.data.data
        })
        setTimeout(() => {
          router.push({
            name: 'PreviewPdf',
            params: data.urlFil + data.backId
          })
          // clearInterval(data.timer)
        }, 1000)
        // window.open(window.api.apiURL + "File/Download?fileId=" + data.backId)
      },
      openWiringView() {
        const dataOne = data.wiringId
        DownloadPdfPic(dataOne).then(res => {
          data.wiringId = res.data.data
        })
        setTimeout(() => {
          router.push({
            name: 'PreviewPdf',
            params: data.urlFil + data.wiringId
          })
          // clearInterval(data.timer)
        }, 1000)
        // window.open(window.api.apiURL + "File/Download?fileId=" + data.wiringId)
      },
      returnWX() {
        // console.log(data.returnwx, '-----------')
        router.push({ name: 'Home', query: JSON.parse(sessionStorage.getItem('URLquery')) })
        sessionStorage.clear()
        // location.reload()
      }

    }

    return { ...toRefs(data), ...methodsMap, loading, progressRef, wx }
  },
  // beforeRouteEnter(to, from, next) {
  //   console.log(to);
  //   console.log(from);
  //   next()
  // }

}
</script>
<style scoped lang="scss">
.box {
  text-align: center;
  .loading {
    width: 342px;
    height: 39px;
    border: 3px solid #04cc66;
    margin: 0 auto;
    margin-top: 213px;
    border-radius: 20px;
    background: url('./../assets/images/loading.png') no-repeat;
    background-size: 100% 100%;
    overflow: hidden;
    position: relative;
    img {
      width: 335px;
      height: 32px;
    }
    .block {
      position: absolute;
      width: 336px;
      height: 34px;
      background-color: #f3f3f4;
      // border-radius: 20px;
      // top: -2px;
      right: 0;
    }
  }
  .text {
    margin-top: 18px;
    // margin-bottom: 123px;
    p {
      font-size: 14px;
      font-weight: bold;
      color: #696c74;
      line-height: 23px;
      // letter-spacing: 44px;
    }
  }
  .grey,
  .light,
  .back {
    width: 343px;
    height: 46px;
    line-height: 46px;
    background: #c7c9cc;
    border-radius: 8px;
    font-size: 14px;
    font-weight: bold;
    color: #ffffff;
    margin: 19px auto;
  }
  .light {
    background: #04cc66;
  }
  .back {
    border: 1px solid #04cc66;
    background-color: #f3f3f4;
    color: #04cc66;
  }
  .aaa{
    text-decoration: none;
    padding: 16px 135px;
  }
}
progress {
  width: 342px;
  height: 39px;
  border-radius: 20px;
    // margin-top: 213px;
    margin-top: 130px;
}
/* 表示总长度背景色 */
progress::-webkit-progress-bar {
  background: #f5f5f5;
    border-radius: 20px;
  border: 3px solid #04cc66;
  padding: 2px;
}
/* 表示已完成进度背景色 */
progress::-webkit-progress-value {
  background: #04cc66;
  border-radius:20px;
}
</style>
